// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cx-js": () => import("./../../../src/pages/cx.js" /* webpackChunkName: "component---src-pages-cx-js" */),
  "component---src-pages-epm-js": () => import("./../../../src/pages/epm.js" /* webpackChunkName: "component---src-pages-epm-js" */),
  "component---src-pages-erp-js": () => import("./../../../src/pages/erp.js" /* webpackChunkName: "component---src-pages-erp-js" */),
  "component---src-pages-hcm-js": () => import("./../../../src/pages/hcm.js" /* webpackChunkName: "component---src-pages-hcm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-realestate-js": () => import("./../../../src/pages/industries/realestate.js" /* webpackChunkName: "component---src-pages-industries-realestate-js" */)
}

