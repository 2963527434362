module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#333","showSpinner":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://adf7ba7619164ff9a740e6bc2a69c2b0@o507169.ingest.sentry.io/6167181","sampleRate":0.7,"environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"minimal-ui","icon":"./static/logo.png","name":"mivors application","short_name":"mivors","description":"This application is your gate to subscribe to mivors, the oracle cloud experts.","start_url":"/","background_color":"#f7f0eb","theme_color":"#0097c2","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[["UA-166099724-4"]],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
